/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { rhythm } from "../utils/typography";
import ReactHtmlParser from "react-html-parser";
import SmoothCollapse from "react-smooth-collapse";
const StyledAccordion = styled.section`
  padding: 4.5rem 5vw ${rhythm(3)};

  a {
    display: flex;
    cursor: pointer;
    &.active {
      h4 {
        color: #91c508;
      }
      span {
        background: #91c508;
      }
    }
    @media screen and (min-width: 1024px) {
      &:hover {
        h4 {
          color: #91c508;
        }
        span {
          background: #91c508;
        }
      }
    }
  }
  p,
  ul,
  ol {
    color: #555;

    margin-left: calc(20px + ${rhythm(1)});
  }
  h4 {
    color: #333;
    margin-top: ${rhythm(1)};
  }
  h1 {
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    font-weight: 300;
    margin-bottom: ${rhythm(2)};
  }
  span {
    margin-top: ${rhythm(1)};
    margin-right: ${rhythm(1)};
    background: black;

    justify-content: center;
    align-items: center;
    display: flex;
    width: fit-content;
    height: fit-content;

    padding: 5px;
    font-size: 10px;
    color: #fff;
    border-radius: 50%;
    -webkit-transition: -webkit-transform 0.2s linear;
    transition: -webkit-transform 0.2s linear;
    transition: transform 0.2s linear;
    transition: transform 0.2s linear, -webkit-transform 0.2s linear;
    &.active {
      transform: rotate(45deg);
    }
  }

`;

const ContainerRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-around;

  }
`;

const AccordionCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;

  @media (max-width: 1024px) {
    .grid-item {
      flex-basis: 100% !important;
    }
  }
  .grid-item {
    display: flex;
    position: relative;
    box-sizing: border-box;
    z-index: 1;
    &:last-of-type,
    :nth-last-of-type(2) {
      .content {
        border-bottom: 1px solid #ccc;
      }
    }
    &:nth-last-of-type(2) {
      .content {
        @media (max-width: 768px) {
          border-bottom: 0px solid #ccc;
        }
      }
    }
    .content {
      width: 100%;
      border-top: 1px solid #ccc;
    }
    &:nth-of-type(odd) {
     
    }
    &:nth-of-type(even) {
     
    }
  }
`;

const ButtonGalleryContainer = styled.div`
  width: 100%;
  margin-top: -22px;
  margin-bottom: 80px;
  display: flex;
`;

const ButtonGallery = styled.button`
  border: none;
  cursor: pointer;
  background: #222;
  color: white;
  font-weight: 700;
  min-width: 170px;
  padding: 3px 15px;
  text-align:center;
  margin-left: auto;
  margin-right: auto;
  span {
    font-weight: 300;
  }
`;



const initArray = (array,maxNum) => {

    let rsp = [];

    for(let i = 0; i < maxNum; i++){
      rsp.push(array[i]);
    }

    return rsp;
  };

const Accordion = (props) => {
  const [active, setActive] = useState(false);
  const [accordionIsOpen, setAccordionIsOpen] = useState(false);
  const [itemsAccordion,setItemsAccordion] = useState(initArray(props.items,10));
  const [col1, setCol1] = useState([]);
  const [col2, setCol2] = useState([]);
  const [maxItem, setMaxItem] = useState(10);

  const initCols = () => {
    const aux1 = [];
    const aux2 = [];
    
    if(maxItem === 10) {
      for(let i = 0; i < maxItem; i++){
      
        if(i < 5) {
          //SE METE DATOS EN LA PRIMERA COLUMNA
          aux1.push(itemsAccordion[i]);
        }else {
          //SE META DATOS EN LA SEGUNDA COLUMNA
          aux2.push(itemsAccordion[i]);
        }
      }
    }else {
      for(let i = 0; i < maxItem; i++){
      
        if(i < 10) {
          //SE METE DATOS EN LA PRIMERA COLUMNA
          aux1.push(itemsAccordion[i]);
        }else {
          //SE META DATOS EN LA SEGUNDA COLUMNA
          aux2.push(itemsAccordion[i]);
        }
      }
    }

    setCol1(aux1);
    setCol2(aux2);
  }

  useEffect(() => {
    initCols();
  },[itemsAccordion]);

  const changeAccordion = () => {
    setAccordionIsOpen(!accordionIsOpen);

    if(!accordionIsOpen){
      //setItemsAccordion(initArray(props.items));
      setMaxItem(20);
      setItemsAccordion(initArray(props.items,20))
      
    }else{
      setMaxItem(10);
      setItemsAccordion(initArray(props.items,10))
      if(typeof window !== undefined){

        let posY = window.scrollY;
        if(window.screen.width < 768){
          window.scroll(0,posY - 1000);
        }
      } 
      
    }

    
    
  }; 

  return (
    <>
      <StyledAccordion>
      <h1>{props.title}</h1>
      {/*itemsAccordion.map((i, k) => {
        return (
          <div key={k} className="grid-item">rvención?
            <div className="content">
              <a
                className={active === k && "active"}
                onClick={(e) => {
                  e.preventDefault();
                  if (active === k) {
                    setActive(false);
                  } else {
                    setActive(k);
                  }
                }}
              >
                <span className={active === k ? "active" : ""}>
                  <i className="icon-plus"></i>
                </span>{" "}
                {ReactHtmlParser(i.title)}
              </a>
              <SmoothCollapse expanded={active === k}>
                {ReactHtmlParser(i.content)}
              </SmoothCollapse>
            </div>
          </div>
        );
      })*/}
      
      <ContainerRow>
        <AccordionCol>
          {col1.map((i,k) => {
              return (
                <div key={k} className="grid-item">
                  <div className="content">
                    <a
                      className={(active.col === 'col1' && active.index === k) && "active"}
                      onClick={(e) => {
                        e.preventDefault();
                        if (active.index === k) {
                          setActive(false);
                        } else {
                          setActive({ col: 'col1', index: k });
                        }
                      }}
                    >
                      <span className={active.col === 'col1' && active.index === k ? "active" : ""}>
                        <i className="icon-plus"></i>
                      </span>{" "}
                      {ReactHtmlParser(i.title)}
                   </a>
                  <SmoothCollapse expanded={active.col === "col1" && active.index === k}>
                    {ReactHtmlParser(i.content)}
                  </SmoothCollapse>
                </div>
              </div>
            );  
          })}
        </AccordionCol>

        <AccordionCol>
          {col2.map((i,k) => {
              return (
                <div key={k} className="grid-item">
                  <div className="content">
                    <a
                      className={(active.col === 'col2' && active.index === k) && "active"}
                      onClick={(e) => {
                        e.preventDefault();
                        if (active.index === k) {
                          setActive(false);
                        } else {
                          setActive({ col: 'col2', index: k });
                        }
                      }}
                    >
                      <span className={(active.col === 'col2' && active.index === k) ? "active" : ""}>
                        <i className="icon-plus"></i>
                      </span>{" "}
                      {ReactHtmlParser(i.title)}
                   </a>
                  <SmoothCollapse expanded={active.col === "col2" && active.index === k}>
                    {ReactHtmlParser(i.content)}
                  </SmoothCollapse>
                </div>
              </div>
            );  
          })}
        </AccordionCol>
      </ContainerRow>

     </StyledAccordion>
     <ButtonGalleryContainer>
          <ButtonGallery onClick={() => {
            changeAccordion();
          }}>
            {accordionIsOpen ? <span>{props.less}</span> : <span>{props.more}</span>}
          </ButtonGallery>
      </ButtonGalleryContainer>
    </>
  );
};

export default Accordion;
